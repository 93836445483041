import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  production: true,
  portierUrl: 'https://0lpjrpswr7.execute-api.eu-central-1.amazonaws.com/production/graphql',
  sharing: {
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot',
    twitterUsername: 'smartvoteCH'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<info@smartvote.org>',
  contactFormToAddress: 'info@smartvote.org',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 10
  },
  cms: {
    repo: 'smartvote-international',
    version: 'Master',
    languageMapping: {
      en: 'en-us',
      de: 'de-ch',
      fr: 'fr-ch'
    },
    tags: ['2021-demo', 'voter']
  },
  profileModuleConfiguration: {
    hasSmartspider: true,
    showShareButton: true
  },
  matchingModuleConfiguration: { hasSmartmap: true, showDividingLine: true },
  questionnaireModuleConfiguration: { showWeight: true },
  questionsAboutYouModuleConfiguration: { showUserSurvey: true },
  demoModuleConfiguration: {
    layoverClosesAfterSeconds: 30,
    enabled: false,
    maxInactivityInSeconds: 180
  },
  showPrivacyLayover: true
}
