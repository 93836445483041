/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reset-demo-layover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./reset-demo-layover.component";
import * as i4 from "../demo.module.configuiration";
var styles_ResetDemoLayoverComponent = [i0.styles];
var RenderType_ResetDemoLayoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResetDemoLayoverComponent, data: {} });
export { RenderType_ResetDemoLayoverComponent as RenderType_ResetDemoLayoverComponent };
export function View_ResetDemoLayoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "demo-reset-layover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "demo-reset-layover__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Resetting demo mode"])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose \"Reset now\" to delete all your answers and return back to the homepage. Choose \"Cancel\" to continue with your answers."])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you take no action, the page will be reset in "])), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" seconds"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "button button-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetNow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reset now"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.duration - i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.interval))); _ck(_v, 11, 0, currVal_0); }); }
export function View_ResetDemoLayoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-reset-demo-layover", [], null, null, null, View_ResetDemoLayoverComponent_0, RenderType_ResetDemoLayoverComponent)), i1.ɵdid(1, 245760, null, 0, i3.ResetDemoLayoverComponent, [i4.DEMO_MODULE_CONFIG, i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetDemoLayoverComponentNgFactory = i1.ɵccf("svi-reset-demo-layover", i3.ResetDemoLayoverComponent, View_ResetDemoLayoverComponent_Host_0, {}, { close: "close", reset: "reset" }, []);
export { ResetDemoLayoverComponentNgFactory as ResetDemoLayoverComponentNgFactory };
