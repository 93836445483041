<svi-navbar></svi-navbar>
<svi-page-title class="d-none d-sm-block" i18n="@@home.title"
  >Welcome to smartvote International</svi-page-title
>
<div class="home-page">
  <section class="head">
    <div class="content hero">
      <h1 i18n="@@home.imageTitle">Demo Elections</h1>
      <span class="subtitle" i18n="@@home.imageSubtitle"
        >Find candidates & parties that share your opinions!</span
      >
      <button class="button button-start" (click)="startQuestionnaire()">
        <ng-container i18n="@@home.startButton">Start now</ng-container>
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
    <div *ngIf="recommendationCount >= 0" class="sticker">
      <div class="content">
        <span class="text-small" i18n="@@home.recommendationCounter1">Already</span>
        <span class="voter-count">{{ recommendationCountLabel }}</span>
        <span class="text-small" i18n="@@home.recommendationCounter2">times used</span>
      </div>
    </div>
  </section>

  <div class="cms-tiles-container">
    <div [class]="doc.className" *ngFor="let doc of (docs | async)" [innerHTML]="doc.html"></div>
  </div>
</div>
